<template>
  <div style="background: #eee">
    <div class="d-flex">
      <div class="flex-fill" style="width: calc(100% - 100px)">
        <div :id="`toolbar-container-${editorId}`"></div>
      </div>
      <div class="p-2" @click="changeMode()" v-if="!hideHtml">
        <check-box title="Sửa html" :value="htmlMode" />
      </div>
    </div>
    <div style="overflow: auto" v-show="!htmlMode">
      <div class="p-2" :style="{ width: $props.width, margin: 'auto' }">
        <div class="bg-white">
          <div :id="`editor-${editorId}`" />
        </div>
      </div>
    </div>
    <code>
      <textarea
        v-show="htmlMode"
        v-model="htmlValue"
        class="form-control"
        rows="20"
      />
    </code>
  </div>
</template>

<script>
import CheckBox from "./CheckBox.vue";
export default {
  components: { CheckBox },
  props: ["width", "value", "input", "editorId", "hideHtml"],
  data() {
    return {
      content: "",
      editor: null,
      htmlMode: false,
      htmlValue: "",
    };
  },

  methods: {
    changeMode() {
      if (this.htmlMode) {
        var value = this.htmlValue.replace(/\n/g, "");
        this.editor.setData(value);
        this.htmlMode = false;
      } else {
        this.htmlMode = true;
        this.htmlValue = this.value.replace(/></g, ">\n<");
      }
    },
    update(value) {
      if (this.editor) {
        this.editor.setData(value);
      }
    },
    addContent(text) {
      this.editor.model.change((writer) => {
        writer.insertText(
          text,
          this.editor.model.document.selection.getFirstPosition()
        );
      });
    },
    setEditor() {
      window.DecoupledEditor.create(
        document.querySelector("#editor-" + this.editorId),
        {
          initialData: this.value || "<p></p>",
          extraPlugins: [UploadImage],
          allowedContent: true,
        }
      ).then((editor) => {
        console.log("Editor was initialized", editor);
        this.editor = editor;
        const toolbarContainer = document.querySelector(
          "#toolbar-container-" + this.editorId
        );
        editor.setData(this.value);
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        editor.model.document.on("change", () => {
          var value = editor.getData();
          this.$emit("input", value);
          this.$emit("change", value);
        });
      });
    },
  },
  mounted() {
    console.log("mounted", this.value);
    this.setEditor();
  },
};
class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve) => {
          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

          return toBase64(file).then((cFile) => {
            this.loader.uploaded = true;
            resolve({
              default: cFile,
            });
          });
        })
    );
  }
}

function UploadImage(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}
</script>

<style lang="scss">
.ck-editor__editable {
  min-height: 300px;
}
</style>
