import Vue from "vue";

export const BILL_TEMPLATE_CONFIG = {
  tags: [
    { key: "billData", name: "Danh sách SP" },
    { key: "logo", name: "Logo công ty" },
    { key: "customerPhone", name: "SĐT khách" },
    { key: "customerName", name: "Tên khách" },
    { key: "customerAddress", name: "Địa chỉ khách" },
    { key: "status", name: "Trạng thái đơn" },
    { key: "branchAddress", name: "Địa chỉ chi nhánh" },
    { key: "code", name: "Mã" },
    { key: "invoiceDate", name: "Ngày tạo" },
    { key: "invoiceCreateByUserName", name: "Người tạo" },
    { key: "personInCharge.fullName", name: "Nhân viên phụ trách" },
    { key: "branchPhone", name: "SĐT chi nhánh" },
    { key: "companyPhone", name: "SĐT cty" },
    { key: "companyName", name: "Tên Cty" },
    { key: "companyTaxNumber", name: "MST cty" },
    { key: "locationName", name: "Bàn" },
    { key: "channels", name: "Kênh bán" },
    { key: "areaName", name: "Phòng" },
    { key: "total", name: "Tổng giá" },

    { key: "totalDiscount", name: "Tổng giảm giá" },
    { key: "totalDiscountProduct", name: "Tổng giảm giá SP" },

    { key: "cashDiscount", name: "Giảm giá tiền mặt" },
    { key: "voucherDiscount", name: "Giảm giá voucher" },
    { key: "couponDiscount", name: "Giảm giá coupon" },
    { key: "totalDiscountInvoice", name: "Tổng CK/KM đơn" },

    { key: "tax", name: "Thuế" },
    { key: "grandTotal", name: "Tổng đơn hàng" },
    { key: "beforeSaleOffTotal", name: "Tổng tiền hàng" },
    { key: "totalPayment", name: "Tổng thanh toán" },
    { key: "cashTotal", name: "Tổng tiền mặt" },
    { key: "cardTotal", name: "Tổng chuyển khoản" },
    { key: "totalReturn", name: "Tổng trả lại KH" },
    { key: "surcharge", name: "Tổng phụ phí" },
    { key: "description", name: "Ghi chú" },
    { key: "datePrint", name: "Ngày in" },
    { key: "totalDebt", name: "Tổng nợ khách hàng" },
    { key: "totalProduct", name: "Tổng hàng bán" },
    { key: "totalGift", name: "Tổng quà" },
    { key: "totalQuantity", name: "Tổng hàng" },

    { key: "cashTotalMoney", name: "Tiền mặt khách đưa" },
    { key: "cashTotalReturnMoney", name: "Tiền mặt trả lại khách" },

    { key: "cardTotalMoney", name: "Chuyển khoản từ khách" },
    { key: "cardTotalReturnMoney", name: "Chuyển khoản lại khách" },

    { key: "remainPayment", name: "Tiền chưa thanh toán" },
    { key: "paidMoney", name: "Tiền đã thanh toán" },

    { key: "totalMoney", name: "Tổng tiền khách đưa" },
    { key: "totalMoneyReturn", name: "Tổng tiền trả lại khách" },

    { key: "extraCard", name: "Tiền CK dư" },
    { key: "extraCash", name: "Tiền mặt dư" },

    { key: "extraMoney", name: "Tiền dư" },
    { key: "paymentDetail", name: "Thông tin thanh toán" },
  ],
  tagBills: [
    { key: "p.index", name: "STT" },
    { key: "p.productCode", name: "Mã SP" },
    { key: "p.productName", name: "Tên SP" },
    { key: "p.unitName", name: "Đơn vị" },

    { key: "p.productQuantity", name: "Tổng số lượng" },
    { key: "p.totalProduct", name: "Tổng hàng bán" },
    { key: "p.totalGift", name: "Tổng quà" },

    { key: "p.productPrice", name: "Giá sản phẩm" },

    { key: "p.saleOffMoney", name: "Đơn gía khuyến mãi" },
    { key: "p.saleOffValue", name: "Giá trị khuyến mãi" },

    { key: "p.surchargeMoney", name: "Phụ phí tiền" },
    { key: "p.surchargeValue", name: "Giá trị phụ phí" },
    { key: "p.discountCustomerPercent", name: "Phần trăm chiết khấu" },

    { key: "p.priceAfter", name: "Đơn giá" },

    { key: "p.cashSaleOff", name: "Tổng khuyến mãi" },
    { key: "p.totalDiscount", name: "Tổng giảm giá" },

    { key: "p.total", name: "Tổng tiền hàng" },
    { key: "p.discountCustomerMoney", name: "Tổng chiết khấu" },
    { key: "p.surcharge", name: "Phụ phí" },

    { key: "p.taxMoney", name: "Thuế SP" },
    { key: "p.percentTax", name: "Thuế SP" },
    { key: "p.totalPayment", name: "Thành tiền" },
  ],
  bills: [
    {
      size: "700px",
      key: "ORDER-58MM",
      type: "ORDER",
      name: "Hoá đơn (58mm)",
      css: `
                p,strong, span{ font-size: 13px; margin: 0; }
                h1 strong,h2 strong,h3 strong, h4 strong{font-size: 20px!important}
                small{ font-size: 11px;  margin: 0; }
                table{ width: 100%; }
      `,
      billData: `<figure class="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <p style="text-align:center;">STT</p>
                                        </th>
                                        <th>
                                            <p style="text-align:center;">Đơn vị</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL hàng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL tặng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Đơn giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Thành tiền</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="6">
                                            <p>#$p.productCode$</p>
                                            <p><strong>$p.productName$</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style="text-align:center;">$p.index$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:center;">$p.unitName$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalProduct$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalGift$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.productPrice$   <span class="text-small"><s>$p.saleOffMoney$</s></span></p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalPayment$</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>`,
      content: `
                    <p style="text-align:center;">$logo$</p>
                    <p style="text-align:center;">$branchAddress$</p>
                    <p style="text-align:center;">Điện thoại: $branchPhone$</p>
                    <h4 style="text-align:center;"><strong>Phiếu thanh toán</strong></h4>
                    <p style="text-align:center;">($status$ - $channels$)</p>
                    <p style="text-align:center;">Số bill: $code$</p>
                    <p style="text-align:center;">KH: $customerName$  - $customerPhone$</p>
                    <p style="text-align:center;">$customerAddress$</p>
                    <p style="text-align:center;">Ngày: $invoiceDate$</p>
                    <p style="text-align:center;">Nhân viên: &nbsp;$invoiceCreateByUserName$</p>
                    $billData$
                    <figure class="table">
                        <table>
                            <tbody>
                                <tr>
                                <td>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                </td>
                                    <td>
                                        <p style="text-align:right;">Tổng:</p>
                                        <p style="text-align:right;">Khuyến mãi:</p>
                                        <p style="text-align:right;">Phụ phí:</p>
                                        <p style="text-align:right;">Thuế:</p>
                                        <p style="text-align:right;"><strong>Tiền cần thanh toán:</strong></p>
                                        <p style="text-align:right;">Tiền khách đưa:</p>
                                        <p style="text-align:right;"><span class="text-small">Tiền mặt:</span></p>
                                        <p style="text-align:right;"><span class="text-small">Chuyển khoản:</span></p>
                                        <p style="text-align:right;"><strong>Tổng trả khách:</strong></p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;">$total$</p>
                                        <p style="text-align:right;">$totalDiscount$</p>
                                        <p style="text-align:right;">$surcharge$</p>
                                        <p style="text-align:right;">$tax$</p>
                                        <p style="text-align:right;"><strong>$grandTotal$</strong></p>
                                        <p style="text-align:right;">$totalPayment$</p>
                                        <p style="text-align:right;"><span class="text-small">$cashTotal$</span></p>
                                        <p style="text-align:right;"><span class="text-small">$cardTotal$</span></p>
                                        <p style="text-align:right;"><strong>$totalReturn$</strong></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </figure>
                    <p style="text-align:center;">!!______________❤❤_____________!!</p>
                    <p style="text-align:center;">Cảm ơn quý khách!</p>
      `,
    },
    {
      size: "700px",
      key: "ORDER-88MM",
      type: "ORDER",
      css: `
                    p,strong, span{ font-size: 13px; margin: 0; }
                    h1 strong,h2 strong,h3 strong, h4 strong{font-size: 20px!important}
                    small{ font-size: 11px;  margin: 0; }
                    table{ width: 100%; }`,
      name: "Hoá đơn (88mm)",
      billData: `<figure class="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <p style="text-align:center;">STT</p>
                                        </th>
                                        <th>
                                            <p style="text-align:center;">Đơn vị</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL hàng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL tặng</p>
                                        </th>   
                                        <th>
                                            <p style="text-align:right;">Đơn giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Giảm giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Thành tiền</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="6">
                                            <p>#$p.productCode$</p>
                                            <p><strong>$p.productName$</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style="text-align:center;">$p.index$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:center;">$p.unitName$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalProduct$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalGift$</p>
                                        </td>
                                        <td>
                                        <p style="text-align:right;">$p.productPrice$  <span class="text-small"><s>$p.saleOffMoney$</s></span></p>

                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.cashSaleOff$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalPayment$</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>`,
      content: `
                        <p style="text-align:center;">$logo$</p>
                        <p style="text-align:center;">$branchAddress$</p>
                        <p style="text-align:center;">Điện thoại: $branchPhone$</p>
                        <h4 style="text-align:center;"><strong>Phiếu thanh toán</strong></h4>
                        <p style="text-align:center;">($status$ - $channels$)</p>
                        <p style="text-align:center;">Số bill: $code$</p>
                        <p style="text-align:center;">KH: $customerName$  - $customerPhone$</p>
                        <p style="text-align:center;">$customerAddress$</p>
                        <p style="text-align:center;">Ngày: $invoiceDate$</p>
                        <p style="text-align:center;">Nhân viên: &nbsp;$invoiceCreateByUserName$</p>
                        $billData$
                        <figure class="table">
                            <table>
                                <tbody>
                                    <tr>
                                    <td>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    </td>
                                        <td>
                                            <p style="text-align:right;">Tổng:</p>
                                            <p style="text-align:right;">Khuyến mãi:</p>
                                            <p style="text-align:right;">Phụ phí:</p>
                                            <p style="text-align:right;">Thuế:</p>
                                            <p style="text-align:right;"><strong>Tiền cần thanh toán:</strong></p>
                                            <p style="text-align:right;">Tiền khách đưa:</p>
                                            <p style="text-align:right;"><span class="text-small">Tiền mặt:</span></p>
                                            <p style="text-align:right;"><span class="text-small">Chuyển khoản:</span></p>
                                            <p style="text-align:right;"><strong>Tổng trả khách:</strong></p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$total$</p>
                                            <p style="text-align:right;">$totalDiscount$</p>
                                            <p style="text-align:right;">$surcharge$</p>
                                            <p style="text-align:right;">$tax$</p>
                                            <p style="text-align:right;"><strong>$grandTotal$</strong></p>
                                            <p style="text-align:right;">$totalPayment$</p>
                                            <p style="text-align:right;"><span class="text-small">$cashTotal$</span></p>
                                            <p style="text-align:right;"><span class="text-small">$cardTotal$</span></p>
                                            <p style="text-align:right;"><strong>$totalReturn$</strong></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>
                        <p style="text-align:center;">!!______________❤❤_____________!!</p>
                        <p style="text-align:center;">Cảm ơn quý khách!</p>
      `,
    },
    {
      size: "900px",
      type: "ORDER",
      key: "ORDER-A4",
      css: `
                p,strong, span{ font-size: 15px; margin: 0; }
                h1 strong,h2 strong,h3 strong, h4 strong{font-size: 20px!important}
                small{ font-size: 12px;  margin: 0; }
                table{ width: 100%; }`,
      name: "Hoá đơn (A4)",
      billData: `<figure class="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <p style="text-align:center;">STT</p>
                                        </th>
                                        <th>
                                            <p style="text-align:left;">Tên</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL hàng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL tặng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Đơn giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Tổng tiền hàng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Chiết khấu</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Giảm giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Phụ phí</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Thành tiền</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p style="text-align:center;">$p.index$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:left;">$p.productName$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalProduct$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalGift$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.productPrice$</p>
                                        </td>

                                        <td>
                                            <p style="text-align:right;">$p.total$</p>
                                        </td>
                                        
                                        <td>
                                            <p style="text-align:right;">$p.surcharge$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.cashSaleOff$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.discountCustomer$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalPayment$</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>`,
      content: `<figure class="table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>$logo$</p>
                                        <p>$branchAddress$</p>
                                        <p>Điện thoại: $branchPhone$</p>
                                        <p>------------</p>
                                        <p>KH: $customerName$  - $customerPhone$</p>
                                        <p>$customerAddress$</p>
                                    </td>
                                    <td>
                                        <h4 style="text-align:center;"><strong>Phiếu thanh toán</strong>&nbsp;</h4>
                                        <p style="text-align:center;">($status$ - $channels$)</p>
                                        <p style="text-align:center;">Số bill: $code$&nbsp;</p>
                                        <p style="text-align:center;">Ngày: $invoiceDate$</p>
                                        <p style="text-align:center;">Nhân viên: $invoiceCreateByUserName$</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </figure>
                    $billData$
                    <figure class="table">
                        <table>
                            <tbody>
                                <tr>
                                <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                    <td>
                                        <p style="text-align:right;">Tổng:</p>
                                        <p style="text-align:right;">Khuyến mãi:</p>
                                        <p style="text-align:right;">Phụ phí:</p>
                                        <p style="text-align:right;">Thuế:</p>
                                        <p style="text-align:right;"><strong>Tiền cần thanh toán:</strong></p>
                                        <p style="text-align:right;">Tiền khách đưa:</p>
                                        <p style="text-align:right;"><span class="text-small">Tiền mặt:</span></p>
                                        <p style="text-align:right;"><span class="text-small">Chuyển khoản:</span></p>
                                        <p style="text-align:right;"><strong>Tổng trả khách:</strong></p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;">$total$</p>
                                        <p style="text-align:right;">$totalDiscount$</p>
                                        <p style="text-align:right;">$surcharge$</p>
                                        <p style="text-align:right;">$tax$</p>
                                        <p style="text-align:right;"><strong>$grandTotal$</strong></p>
                                        <p style="text-align:right;">$totalPayment$</p>
                                        <p style="text-align:right;"><span class="text-small">$cashTotal$</span></p>
                                        <p style="text-align:right;"><span class="text-small">$cardTotal$</span></p>
                                        <p style="text-align:right;"><strong>$totalReturn$</strong></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </figure>
                    <p style="text-align:center;">!!______________❤❤_____________!!</p>
                    <p style="text-align:center;">Cảm ơn quý khách!</p>`,
    },
    {
      size: "800px",
      type: "ORDER",
      key: "ORDER-A5",
      css: `
                p,strong, span{ font-size: 15px; margin: 0; }
                h1 strong,h2 strong,h3 strong, h4 strong{font-size: 20px!important}
                small{ font-size: 12px;  margin: 0; }
                table{ width: 100%; }`,
      name: "Hoá đơn (A5)",
      billData: `<figure class="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <p style="text-align:center;">STT</p>
                                        </th>
                                        <th>
                                            <p style="text-align:left;">Tên</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL hàng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL tặng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Đơn giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Giảm giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Thành tiền</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p style="text-align:center;">$p.index$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:left;">$p.productName$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalProduct$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalGift$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.productPrice$   <span class="text-small"><s>$p.saleOffMoney$</s></span></p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.cashSaleOff$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalPayment$</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>`,
      content: `<figure class="table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>$logo$</p>
                                        <p>$branchAddress$</p>
                                        <p>Điện thoại: $branchPhone$</p>
                                        <p>------------</p>
                                        <p>KH: $customerName$  - $customerPhone$</p>
                                        <p>$customerAddress$</p>
                                    </td>
                                    <td>
                                        <h4 style="text-align:center;"><strong>Phiếu thanh toán</strong>&nbsp;</h4>
                                        <p style="text-align:center;">($status$ - $channels$)</p>
                                        <p style="text-align:center;">Số bill: $code$&nbsp;</p>
                                        <p style="text-align:center;">Ngày: $invoiceDate$</p>
                                        <p style="text-align:center;">Nhân viên: $invoiceCreateByUserName$</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </figure>
                    $billData$
                    <figure class="table">
                        <table>
                            <tbody>
                                <tr>
                                <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                    <td>
                                        <p style="text-align:right;">Tổng:</p>
                                        <p style="text-align:right;">Khuyến mãi:</p>
                                        <p style="text-align:right;">Phụ phí:</p>
                                        <p style="text-align:right;">Thuế:</p>
                                        <p style="text-align:right;"><strong>Tiền cần thanh toán:</strong></p>
                                        <p style="text-align:right;">Tiền khách đưa:</p>
                                        <p style="text-align:right;"><span class="text-small">Tiền mặt:</span></p>
                                        <p style="text-align:right;"><span class="text-small">Chuyển khoản:</span></p>
                                        <p style="text-align:right;"><strong>Tổng trả khách:</strong></p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;">$total$</p>
                                        <p style="text-align:right;">$totalDiscount$</p>
                                        <p style="text-align:right;">$surcharge$</p>
                                        <p style="text-align:right;">$tax$</p>
                                        <p style="text-align:right;"><strong>$grandTotal$</strong></p>
                                        <p style="text-align:right;">$totalPayment$</p>
                                        <p style="text-align:right;"><span class="text-small">$cashTotal$</span></p>
                                        <p style="text-align:right;"><span class="text-small">$cardTotal$</span></p>
                                        <p style="text-align:right;"><strong>$totalReturn$</strong></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </figure>
                    <p style="text-align:center;">!!______________❤❤_____________!!</p>
                    <p style="text-align:center;">Cảm ơn quý khách!</p>`,
    },
    {
      size: "700px",
      key: "REFUND-58MM",
      type: "REFUND",
      name: "Trả hàng (58mm)",
      css: `
                    p,strong, span{ font-size: 13px; margin: 0; }
                    h1 strong,h2 strong,h3 strong, h4 strong{font-size: 20px!important}
                    small{ font-size: 11px;  margin: 0; }
                    table{ width: 100%; }
      `,
      billData: `<figure class="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <p style="text-align:center;">STT</p>
                                        </th>
                                        <th>
                                            <p style="text-align:center;">Đơn vị</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Đơn giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Thành tiền</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="6">
                                            <p>#$p.productCode$</p>
                                            <p><strong>$p.productName$</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style="text-align:center;">$p.index$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:center;">$p.unitName$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.productQuantity$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.productPrice$  <span class="text-small"><s>$p.saleOffMoney$</s></span></p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalPayment$</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>`,
      content: `
                        <p style="text-align:center;">$logo$</p>
                        <p style="text-align:center;">$branchAddress$</p>
                        <p style="text-align:center;">Điện thoại: $branchPhone$</p>
                        <h4 style="text-align:center;"><strong>Trả hàng</strong></h4>
                        <p style="text-align:center;">($status$ - $channels$)</p>
                        <p style="text-align:center;">Số bill: $code$</p>
                        <p style="text-align:center;">KH: $customerName$  - $customerPhone$</p>
                        <p style="text-align:center;">$customerAddress$</p>
                        <p style="text-align:center;">Ngày: $invoiceDate$</p>
                        <p style="text-align:center;">Nhân viên: &nbsp;$invoiceCreateByUserName$</p>
                        $billData$
                        <figure class="table">
                            <table>
                                <tbody>
                                    <tr>
                                    <td>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    </td>
                                    <td>
                                        <p style="text-align:right;"><strong>Tiền trả khách:</strong></p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;"><strong>$grandTotal$</strong></p>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>
                        <p style="text-align:center;">!!______________❤❤_____________!!</p>
                        <p style="text-align:center;">Cảm ơn quý khách!</p>
      `,
    },
    {
      size: "700px",
      key: "REFUND-88MM",
      type: "REFUND",
      css: `
                p,strong, span{ font-size: 13px; margin: 0; }
                h1 strong,h2 strong,h3 strong, h4 strong{font-size: 20px!important}
                small{ font-size: 11px;  margin: 0; }
                table{ width: 100%; }`,
      name: "Trả hàng (88mm)",
      billData: `<figure class="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <p style="text-align:center;">STT</p>
                                        </th>
                                        <th>
                                            <p style="text-align:center;">Đơn vị</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Đơn giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Giảm giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Thành tiền</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="6">
                                            <p>#$p.productCode$</p>
                                            <p><strong>$p.productName$</strong></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style="text-align:center;">$p.index$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:center;">$p.unitName$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.productQuantity$</p>
                                        </td>
                                        <td>
                                        <p style="text-align:right;">$p.productPrice$  <span class="text-small"><s>$p.saleOffMoney$</s></span></p>

                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.cashSaleOff$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalPayment$</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>`,
      content: `
                        <p style="text-align:center;">$logo$</p>
                        <p style="text-align:center;">$branchAddress$</p>
                        <p style="text-align:center;">Điện thoại: $branchPhone$</p>
                        <h4 style="text-align:center;"><strong>Trả hàng</strong></h4>
                        <p style="text-align:center;">($status$ - $channels$)</p>
                        <p style="text-align:center;">Số bill: $code$</p>
                        <p style="text-align:center;">KH: $customerName$  - $customerPhone$</p>
                        <p style="text-align:center;">$customerAddress$</p>
                        <p style="text-align:center;">Ngày: $invoiceDate$</p>
                        <p style="text-align:center;">Nhân viên: &nbsp;$invoiceCreateByUserName$</p>
                        $billData$
                        <figure class="table">
                            <table>
                                <tbody>
                                    <tr>
                                    <td>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <td>
                                    <p style="text-align:right;"><strong>Tiền trả khách:</strong></p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;"><strong>$grandTotal$</strong></p>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>
                        <p style="text-align:center;">!!______________❤❤_____________!!</p>
                        <p style="text-align:center;">Cảm ơn quý khách!</p>
      `,
    },
    {
      size: "900px",
      key: "REFUND-A4",
      type: "REFUND",
      css: `
                    p,strong, span{ font-size: 15px; margin: 0; }
                    h1 strong,h2 strong,h3 strong, h4 strong{font-size: 20px!important}
                    small{ font-size: 12px;  margin: 0; }
                    table{ width: 100%; }`,
      name: "Trả hàng (A4)",
      billData: `<figure class="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <p style="text-align:center;">STT</p>
                                        </th>
                                        <th>
                                            <p style="text-align:left;">Tên</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Đơn giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Phí</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Thành tiền</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p style="text-align:center;">$p.index$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:left;">$p.productName$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.productQuantity$</p>
                                        </td>
                                        <td>
                                        <p style="text-align:right;">$p.productPrice$   <span class="text-small"><s>$p.saleOffMoney$</s></span></p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.cashSaleOff$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalPayment$</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>`,
      content: `<figure class="table">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>$logo$</p>
                                            <p>$branchAddress$</p>
                                            <p>Điện thoại: $branchPhone$</p>
                                            <p>------------</p>
                                            <p>KH: $customerName$  - $customerPhone$</p>
                                            <p>$customerAddress$</p>
                                        </td>
                                        <td>
                                            <h4 style="text-align:center;"><strong>Trả hàng</strong>&nbsp;</h4>
                                            <p style="text-align:center;">($status$ - $channels$)</p>
                                            <p style="text-align:center;">Số bill: $code$&nbsp;</p>
                                            <p style="text-align:center;">Ngày: $invoiceDate$</p>
                                            <p style="text-align:center;">Nhân viên: $invoiceCreateByUserName$</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>
                        $billData$
                        <figure class="table">
                            <table>
                                <tbody>
                                    <tr>
                                    <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                    <td>
                                    <p style="text-align:right;"><strong>Tiền trả khách:</strong></p>
                                </td>
                                <td>
                                    <p style="text-align:right;"><strong>$grandTotal$</strong></p>
                                </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>
                        <p style="text-align:center;">!!______________❤❤_____________!!</p>
                        <p style="text-align:center;">Cảm ơn quý khách!</p>`,
    },
    {
      size: "800px",
      key: "REFUND-A5",
      type: "REFUND",
      css: `p,strong, span{ font-size: 15px; margin: 0; }
                    h1 strong,h2 strong,h3 strong, h4 strong{font-size: 20px!important}
                    small{ font-size: 12px;  margin: 0; }
                    table{ width: 100%; }`,
      name: "Trả hàng (A5)",
      billData: `<figure class="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <p style="text-align:center;">STT</p>
                                    </th>
                                    <th>
                                        <p style="text-align:left;">Tên</p>
                                    </th>
                                    <th>
                                        <p style="text-align:right;">SL</p>
                                    </th>
                                    <th>
                                        <p style="text-align:right;">Đơn giá</p>
                                    </th>
                                    <th>
                                        <p style="text-align:right;">Giảm giá</p>
                                    </th>
                                    <th>
                                        <p style="text-align:right;">Thành tiền</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p style="text-align:center;">$p.index$</p>
                                    </td>
                                    <td>
                                        <p style="text-align:left;">$p.productName$</p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;">$p.productQuantity$</p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;">$p.productPrice$   <span class="text-small"><s>$p.saleOffMoney$</s></span></p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;">$p.cashSaleOff$</p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;">$p.totalPayment$</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </figure>`,
      content: `<figure class="table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>$logo$</p>
                                        <p>$branchAddress$</p>
                                        <p>Điện thoại: $branchPhone$</p>
                                        <p>------------</p>
                                        <p>KH: $customerName$  - $customerPhone$</p>
                                        <p>$customerAddress$</p>
                                    </td>
                                    <td>
                                        <h4 style="text-align:center;"><strong>Trả hàng</strong>&nbsp;</h4>
                                        <p style="text-align:center;">($status$ - $channels$)</p>
                                        <p style="text-align:center;">Số bill: $code$&nbsp;</p>
                                        <p style="text-align:center;">Ngày: $invoiceDate$</p>
                                        <p style="text-align:center;">Nhân viên: $invoiceCreateByUserName$</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </figure>
                    $billData$
                    <figure class="table">
                        <table>
                            <tbody>
                                <tr>
                                <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                <td>
                                        <p style="text-align:right;"><strong>Tiền trả khách:</strong></p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;"><strong>$grandTotal$</strong></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </figure>
                    <p style="text-align:center;">!!______________❤❤_____________!!</p>
                    <p style="text-align:center;">Cảm ơn quý khách!</p>`,
    },

    {
      size: "900px",
      type: "PROCESS",
      key: "PROCESS-A4",
      css: `
                p,strong, span{ font-size: 15px; margin: 0; }
                h1 strong,h2 strong,h3 strong, h4 strong{font-size: 20px!important}
                small{ font-size: 12px;  margin: 0; }
                table{ width: 100%; }`,
      name: "Hoá đơn - giao hàng (A4)",
      billData: `<figure class="table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <p style="text-align:center;">STT</p>
                                        </th>
                                        <th>
                                            <p style="text-align:left;">Tên</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL hàng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">SL tặng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Đơn giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Tổng tiền hàng</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Chiết khấu</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Giảm giá</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Phụ phí</p>
                                        </th>
                                        <th>
                                            <p style="text-align:right;">Thành tiền</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p style="text-align:center;">$p.index$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:left;">$p.productName$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalProduct$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalGift$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.productPrice$</p>
                                        </td>

                                        <td>
                                            <p style="text-align:right;">$p.total$</p>
                                        </td>
                                        
                                        <td>
                                            <p style="text-align:right;">$p.surcharge$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.cashSaleOff$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.discountCustomer$</p>
                                        </td>
                                        <td>
                                            <p style="text-align:right;">$p.totalPayment$</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>`,
      content: `<figure class="table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>$logo$</p>
                                        <p>$branchAddress$</p>
                                        <p>Điện thoại: $branchPhone$</p>
                                        <p>------------</p>
                                        <p>KH: $customerName$  - $customerPhone$</p>
                                        <p>$customerAddress$</p>
                                    </td>
                                    <td>
                                        <h4 style="text-align:center;"><strong>Phiếu thanh toán</strong>&nbsp;</h4>
                                        <p style="text-align:center;">($status$ - $channels$)</p>
                                        <p style="text-align:center;">Số bill: $code$&nbsp;</p>
                                        <p style="text-align:center;">Ngày: $invoiceDate$</p>
                                        <p style="text-align:center;">Nhân viên: $invoiceCreateByUserName$</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </figure>
                    $billData$
                    <figure class="table">
                        <table>
                            <tbody>
                                <tr>
                                <td>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</td>
                                    <td>
                                        <p style="text-align:right;">Tổng:</p>
                                        <p style="text-align:right;">Khuyến mãi:</p>
                                        <p style="text-align:right;">Phụ phí:</p>
                                        <p style="text-align:right;">Thuế:</p>
                                        <p style="text-align:right;"><strong>Tiền cần thanh toán:</strong></p>
                                        <p style="text-align:right;">Tiền khách đưa:</p>
                                        <p style="text-align:right;"><span class="text-small">Tiền mặt:</span></p>
                                        <p style="text-align:right;"><span class="text-small">Chuyển khoản:</span></p>
                                        <p style="text-align:right;"><strong>Tổng trả khách:</strong></p>
                                    </td>
                                    <td>
                                        <p style="text-align:right;">$total$</p>
                                        <p style="text-align:right;">$totalDiscount$</p>
                                        <p style="text-align:right;">$surcharge$</p>
                                        <p style="text-align:right;">$tax$</p>
                                        <p style="text-align:right;"><strong>$grandTotal$</strong></p>
                                        <p style="text-align:right;">$totalPayment$</p>
                                        <p style="text-align:right;"><span class="text-small">$cashTotal$</span></p>
                                        <p style="text-align:right;"><span class="text-small">$cardTotal$</span></p>
                                        <p style="text-align:right;"><strong>$totalReturn$</strong></p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </figure>
                    <p style="text-align:center;">!!______________❤❤_____________!!</p>
                    <p style="text-align:center;">Cảm ơn quý khách!</p>`,
    },
  ],
};

export const APPCONFIG = [
  {
    name: "Giao diện bán hàng",
    isHeader: true,
  },
  {
    key: "allowChooseLocation",
    name: "Cho phép chọn vị trí bàn / phòng",
    type: "switch",
  },
  {
    key: "allowChooseChannel",
    name: "Cho phép chọn kênh bán hàng",
    type: "switch",
  },
  {
    key: "allowGift",
    name: "Cho phép tặng hàng",
    type: "switch",
  },
  {
    key: "allowDiscountCustomer",
    name: "Cho phép chiết khấu nhóm khách hàng",
    type: "switch",
  },
  {
    key: "allowQRPayment",
    name: "Cho phép thanh toán QR",
    type: "switch",
  },
  {
    key: "allowViewAllCustomer",
    name: "Cho phép xem toàn bộ khách hàng",
    type: "switch",
  },
  {
    name: "Trả hàng",
    isHeader: true,
  },
  {
    key: "allowNoBill",
    name: "Cho phép trả hàng khi không có bill",
    type: "switch",
    isMain: true,
  },
  {
    key: "allowMaxDaysReturn",
    name: "Số ngày cho phép trả hàng",
    type: "number",
    isMain: true,
    prefix: "Ngày",
  },
  {
    name: "Đơn hàng",
    isHeader: true,
  },
  // {
  //   key: "printType",
  //   name: "Loại bản in",
  //   type: "select",
  //   selectList: [
  //     { key: "A4", name: "A4" },
  //     { key: "88MM", name: "88 mm" },
  //     { key: "80MM", name: "80 mm" },
  //     { key: "58MM", name: "58 mm" },
  //   ],
  // },
  {
    key: "isStockCompany",
    name: "Bắt buộc kiểm tra kho",
    type: "switch",
    isMain: true,
  },
  {
    key: "allowMaxDaysDeleteOrder",
    name: "Số ngày cho phép xoá hoá đơn",
    isMain: true,
    type: "number",
    prefix: "Ngày",
  },
  {
    key: "surchargeTypes",
    name: "Các loại phụ phí (cách nhau bằng dấu phẩy ',')",
    type: "textarea",
  },
  {
    name: "Thuế",
    isHeader: true,
  },
  {
    key: "taxPercent",
    name: "Phần trăm thuế",
    isMain: true,
    max: 99,
    min: 0,
    type: "number",
    prefix: "%",
  },
  {
    key: "forceTax",
    name: "Luôn mặc định tính thuế",
    type: "switch",
  },
  {
    key: "allowChangeTax",
    name: "Cho phép thay đổi",
    type: "switch",
  },
  {
    name: "Chiết khấu / Chăm sóc khách hàng",
    isHeader: true,
  },

  {
    key: "moneyOnOnePoint",
    name: "Tiền thành điểm",
    isMain: true,
    type: "money",
    prefix: Vue.prototype.$currency,
  },
  {
    key: "pointToMoney",
    name: "Điểm thành tiền",
    isMain: true,
    type: "money",
    prefix: Vue.prototype.$currency,
  },
  {
    key: "isGetPointMember",
    name: "Cho phép tích điểm thành viên",
    type: "switch",
  },
];

export const MENU_SALE = [
  {
    icon: "you",
    url: "/profile",
    label: "Thông tin cá nhân",
    permission: "DEFAULT",
    children: [
      { url: "/profile", label: "Cá nhân", permission: "DEFAULT" },
      { url: "/profile/team", label: "Phòng ban", permission: "DEFAULT" },
      //   { url: "/company", label: "Chi nhánh/ công ty", permission: "DEFAULT" },
      //   { url: "/profile", label: "Thông tin cá nhân", permission: "DEFAULT" },
    ],
  },
  {
    icon: "tray",
    url: "/order/pos",
    label: "menu.sales",
    permission: "ORDER",
    children: [
      {
        url: "/order",
        label: "menu.all_order",
        permission: "ORDER:ALL",
      },

      { url: "/order/pos", label: "Bán POS", permission: "ORDER:NEW" },
      { url: "/order/sale", label: "Bán hàng", permission: "ORDER:B2B" },
      { url: "/order/Bulk", label: "Nhiều đơn", permission: "ORDER:B2B" },
      { url: "/order/cart", label: "Đặt hàng", permission: "ORDER:CART" },
      {
        url: "/order/track",
        label: "Theo dõi",
        permission: "ORDER:TRACK",
      },
      {
        url: "/order/refund",
        label: "menu.refund",
        permission: "ORDER:REFUND",
      },
    ],
  },
  {
    icon: "money",
    url: "/finance",
    label: "Hạch toán",
    permission: "FINANCE",
    children: [
      {
        url: "/finance",
        label: "Hạch toán",
        permission: "FINANCE",
      },
      {
        url: "/finance?new=income",
        label: "finance.new_income",
        permission: "FINANCE:NEW",
      },
      {
        url: "/finance?new=expense",
        label: "finance.new_expense",
        permission: "FINANCE:NEW",
      },
      {
        url: "/finance/summary",
        label: "Mã kế toán",
        permission: "FINANCE:REPORT",
      },
      {
        url: "/finance/summary?tab=code",
        label: "Đối tượng",
        permission: "FINANCE:REPORT",
      },
    ],
  },
  {
    icon: "work",
    url: "/work",
    label: "Sản xuất",
    permission: "WORK",
    children: [
      {
        url: "/work/track",
        label: "Theo dõi",
        permission: "WORK:VIEW",
      },
      {
        url: "/work/all",
        label: "Tất cả",
        permission: "WORK:VIEW",
      },
      {
        url: "/work/new",
        label: "Tạo mới",
        permission: "WORK:NEW",
      },
      {
        url: "/work/report",
        label: "Báo cáo",
        permission: "WORK:REPORT",
      },
    ],
  },
  {
    icon: "work",
    url: "/shipping",
    label: "Vận chuyển",
    permission: "WORK",
    children: [
      {
        url: "/shipping/track",
        label: "Theo dõi",
        permission: "WORK:VIEW",
      },
      {
        url: "/shipping/all",
        label: "Tất cả",
        permission: "WORK:VIEW",
      },
      {
        url: "/shipping/new",
        label: "Tạo mới",
        permission: "WORK:NEW",
      },
      {
        url: "/shipping/report",
        label: "Báo cáo",
        permission: "WORK:REPORT",
      },
    ],
  },
  {
    icon: "storage",
    url: "/stock",
    label: "menu.stock",
    permission: "STOCK",
    children: [
      {
        url: "/stock/state",
        label: "stock.state",
        permission: "STOCK:STATE",
      },
      {
        url: "/stock/purchase",
        label: "Đơn mua",
        permission: "STOCK:IN",
      },
      { url: "/stock/in", label: "stock.in", permission: "STOCK:IN" },
      { url: "/stock/out", label: "stock.out", permission: "STOCK:OUT" },

      {
        url: "/stock/transfer",
        label: "stock.transfer",
        permission: "STOCK:TRANSFER",
      },

      {
        url: "/stock/asset",
        label: "Tài sản",
        permission: "STOCK:ASSET",
      },
    ],
  },
  {
    icon: "report",
    url: "/report",
    label: "Báo cáo chi nhánh",
    permission: "REPORT",
    children: [
      {
        url: "/report/revenue",
        label: "report.revenue",
        permission: "REPORT:REVENUE",
      },
      {
        url: "/report/supplier",
        label: "report.supplier",
        permission: "REPORT:SUPPLIER",
      },
      {
        url: "/report/product",
        label: "report.product",
        permission: "REPORT:PRODUCT",
      },
      {
        url: "/report/shift-sales",
        label: "report.shift_sales",
        permission: "REPORT:SHIFTSALE",
      },
      // {
      //     url: "/report/employee",
      //     label: "NV hưởng DS",
      //     permission: "REPORT",
      // },
      // {
      //     url: "/report/work",
      //     label: "Sản xuất",
      //     permission: "REPORT",
      // },
    ],
  },

  {
    icon: "admin",
    url: "/admin",
    label: "Quản trị công ty",
    permission: "ADMIN",
    children: [
      { url: "/admin/board", label: "Trang quản lý", permission: "ADMIN" },
      {
        url: "/admin/report",
        label: "menu.report",
        permission: "ADMIN:REPORT",
      },

      {
        url: "/admin/supplier",
        label: "menu.supplier",
        permission: "ADMIN:SUPPLIER",
      },
      {
        url: "/admin/customer",
        label: "menu.customer",
        permission: "ADMIN:CUSTOMER",
      },

      {
        url: "/admin/product",
        label: "menu.product",
        permission: "ADMIN:PRODUCT",
      },
      {
        url: "/admin/branch",
        label: "menu.branch",
        permission: "ADMIN:BRANCH",
      },
      {
        url: "/admin/company",
        label: "menu.company",
        permission: "ADMIN:COMPANY",
      },

      {
        url: "/admin/employee",
        label: "menu.employee",
        permission: "ADMIN:EMPLOYEE",
      },
    ],
  },
];
export const MENU = [
  {
    link: "/admin/board",
    name: "Bảng điều khiển",
    icon: "fa fa-comment-dollar",
    permission: "ADMIN",
  },
  {
    name: "Hoạt động kinh doanh",
    title: true,
    permission:
      "ADMIN:REPORT,ADMIN:ORDER,ADMIN:FINANCE,ADMIN:SUPPLIER,ADMIN:STOCK,ADMIN:TARGET",
  },
  {
    link: "/admin/report",
    name: "Báo cáo",
    icon: "fa fa-chart-bar",
    permission: "ADMIN:REPORT",
  },
  {
    link: "/admin/target",
    name: "Doanh số mục tiêu",
    icon: "fa fa-bullseye",
    permission: "ADMIN:TARGET",
  },
  {
    link: "/admin/order",
    permission: "ADMIN:ORDER",
    name: "Bán hàng",
    icon: "fa fa-shopping-cart",
  },
  {
    link: "/admin/stock",
    name: "Kho / hàng hoá",
    permission: "ADMIN:STOCK",
    icon: "fa fa-truck-moving",
  },
  {
    link: "/admin/finance",
    name: "menu.finance",
    permission: "ADMIN:FINANCE",
    icon: "fa fa-comments-dollar",
  },
  {
    link: "/admin/supplier",
    name: "menu.supplier",
    permission: "ADMIN:SUPPLIER",
    icon: "fa fa-dolly",
  },
  {
    name: "Chăm sóc khách hàng",
    title: true,
    permission:
      "ADMIN:CUSTOMER,ADMIN:PROMO,ADMIN:MEMBER,ADMIN:ORG_CUSTOMER,ADMIN:GROUP_CUSTOMER,ADMIN:CAMPAIGN,ADMIN:POTENTIAL",
  },
  {
    link: "/admin/potential",
    name: "Khách hàng tiềm năng",
    permission: "ADMIN:POTENTIAL",
    icon: "fa fa-user",
  },

  {
    link: "/admin/customer",
    name: "menu.customer",
    permission: "ADMIN:CUSTOMER",
    icon: "fa fa-user",
  },
  {
    link: "/admin/org-customer",
    name: "Công ty khách hàng",
    permission: "ADMIN:ORG_CUSTOMER",
    icon: "fa fa-building",
  },

  {
    link: "/admin/group-customer",
    name: "Nhóm khách hàng",
    permission: "ADMIN:GROUP_CUSTOMER",
    icon: "fa fa-users",
  },
  {
    link: "/admin/member",
    permission: "ADMIN:MEMBER",
    name: "Thành viên",
    icon: "fa fa-id-card",
  },
  {
    link: "/admin/campaign",
    permission: "ADMIN:CAMPAIGN",
    name: "Chương trình khuyến mãi",
    icon: "fa fa-percent",
  },
  {
    link: "/admin/promo",
    permission: "ADMIN:PROMO",
    name: "Mã khuyến mãi",
    icon: "fa fa-percent",
  },

  {
    name: "Quản lý",
    title: true,
    permission:
      "ADMIN:CATEGORY,ADMIN:PRODUCT,ADMIN:VARIANT,ADMIN:ORDER_TEMPLATE",
  },

  {
    link: "/admin/category",
    permission: "ADMIN:CATEGORY",
    name: "menu.category",
    icon: "fa fa-object-group",
  },
  {
    link: "/admin/master-product",
    name: "Sản phẩm chính",
    permission: "ADMIN:VARIANT",
    icon: "fa fa-tags",
  },
  {
    link: "/admin/product",
    name: "Sản phẩm",
    permission: "ADMIN:PRODUCT",
    icon: "fa fa-tag",
  },
  {
    link: "/admin/template",
    name: "Mẫu đơn hàng",
    permission: "ADMIN:ORDER_TEMPLATE",
    icon: "fa fa-table",
  },
  {
    name: "Thiếp lập",
    title: true,
    permission:
      "ADMIN:USER,ADMIN:EMPLOYEE,ADMIN:BRANCH,ADMIN:COMPANY,ADMIN:DEPARTMENT,ADMIN:STORE",
  },
  {
    name: "menu.company",
    icon: "fa fa-building",
    link: "/admin/company",
    permission: "ADMIN:COMPANY",
  },
  {
    name: "menu.branch",
    icon: "fa fa-code-branch",
    link: "/admin/branch",
    permission: "ADMIN:BRANCH",
  },
  {
    name: "Phòng ban",
    icon: "fa fa-users",
    link: "/admin/department",
    permission: "ADMIN:DEPARTMENT",
  },
  {
    name: "Nhân viên",
    icon: "fa fa-users",
    link: "/admin/employee",
    permission: "ADMIN:EMPLOYEE",
  },

  {
    name: "menu.user",
    icon: "fa fa-user",
    link: "/admin/user",
    permission: "ADMIN:USER",
  },
  {
    name: "menu.permission",
    icon: "fa fa-user-cog",
    link: "/admin/permission",
    permission: "ADMIN:USER",
  },
  {
    name: "Web bán hàng",
    icon: "fa fa-store",
    link: "/admin/store",
    permission: "ADMIN:STORE",
  },
  {
    name: "App bán hàng",
    icon: "fa fa-mobile",
    link: "/admin/app",
    permission: "ADMIN:STORE",
  },

  {
    name: "App B2B",
    icon: "fa fa-mobile",
    link: "/admin/b2b",
    permission: "ADMIN:STORE",
  },

  {
    name: "Chi nhánh",
    title: true,
    permission: "ORDER,FINANCE,STOCK,REPORT,WORK",
    key: "order",
  },
  {
    name: "menu.all_order",
    icon: "fa fa-list",
    link: "/order",
    permission: "ORDER:ALL",
  },

  {
    name: "Bán POS",
    icon: "fa fa-cart-plus",
    link: "/order/pos",
    permission: "ORDER:NEW",
  },

  {
    name: "Bán hàng",
    icon: "fa fa-shopping-basket",
    link: "/order/sale",
    permission: "ORDER:B2B",
  },
  {
    name: "Đặt hàng",
    icon: "fa fa-cart-plus",
    link: "/order/cart",
    permission: "ORDER:NEW",
  },
  {
    link: "/order/cart",
    name: "Yêu cầu đặt hàng",
    icon: "fa fa-comment-medical",
    permission: "ADMIN:CART",
  },

  {
    name: "Sản xuất",
    icon: "fa fa-cubes",
    link: "/work",
    permission: "WORK",
  },
  {
    name: "menu.finance",
    icon: "fa fa-comment-dollar",
    link: "/finance",
    permission: "FINANCE",
  },
  {
    name: "menu.stock",
    icon: "fa fa-boxes",
    link: "/stock",
    permission: "STOCK",
  },
  {
    name: "menu.report",
    icon: "fa fa-chart-pie",
    link: "/report",
    permission: "REPORT",
  },
];
export const B2BSTEP = [
  {
    name: "Đang chờ",
    code: "init",
    isNew: true,
    disabled: true,
    nextAll: true,
  },
  {
    name: "Hoàn thành",
    code: "done",
    isDone: true,
    isDebt: true,
    isStock: true,
    isEmployeeSale: true,
    disabled: true,
  },
  {
    name: "Hủy bỏ",
    code: "reject",
    isDone: true,
    isReject: true,
    disabled: true,
  },
];

export const RETAILSTEP = [
  {
    name: "Đang chờ",
    code: "init",
    isNew: true,
    nextAll: true,
    disabled: true,
  },
  {
    name: "Hoàn thành",
    code: "done",
    isDone: true,
    disabled: true,
    isDebt: true,
    isStock: true,
  },
  {
    name: "Hủy bỏ",
    code: "reject",
    isDone: true,
    isReject: true,
    disabled: true,
  },
];

export const BOMSTEP = [
  {
    name: "Đang chờ",
    code: "init",
    isNew: true,
    disabled: true,
    nextAll: true,
    allowCreate: true,
  },
  {
    name: "Bắt đầu dây chuyền",
    code: "process",
    outMaterial: true,
    disabled: true,
    nextAll: true,
  },
  {
    name: "Hoàn thành",
    code: "done",
    isDone: true,
    inStock: true,
    disabled: true,
  },
  {
    name: "Hủy bỏ",
    code: "reject",
    isDone: true,
    isReject: true,
    disabled: true,
  },
];
