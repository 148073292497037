<template>
  <section class="menu-sub">
    <div class="menu-list">
      <!-- <router-link
        tag="div"
        class="menu-item"
        exact
        to="/order"
        v-if="$permission('ORDER:ALL')"
        :class="{
          active:
            $route.path.indexOf('/order?') === 0 || $route.path == '/order',
        }"
      >
        <div
          class="img"
          style="background-image: url('/images/shopping-list.png')"
        />
        <div class="title">
          <p>Tất cả đơn</p>
        </div>
      </router-link>
      <router-link
        tag="div"
        class="menu-item"
        :class="{ active: $route.path == '/order/pos' }"
        to="/order/pos"
        exact
        v-if="$permission('ORDER:NEW')"
      >
        <div class="img" style="background-image: url('/images/tray.png')" />
        <div class="title">
          <p>Bán POS</p>
        </div>
      </router-link>
      <router-link
        tag="div"
        class="menu-item"
        to="/order/sale"
        exact
        :class="{ active: $route.path == '/order/sale' }"
        v-if="$permission('ORDER:B2B')"
      >
        <div class="img" style="background-image: url('/images/b2b.png')" />
        <div class="title">
          <p>Bán hàng</p>
        </div>
      </router-link>

      <router-link
        tag="div"
        class="menu-item"
        to="/order/cart"
        exact
        v-if="$permission('ORDER:CART')"
        :class="{
          active:
            $route.path == '/order/cart' ||
            $route.path.indexOf('/order/cart') == 0,
        }"
      >
        <div
          class="img"
          style="background-image: url('/images/shopping-cart.png')"
        />
        <div class="title">
          <p>Đặt hàng</p>
        </div>
      </router-link>

      <router-link
        tag="div"
        class="menu-item"
        to="/order/track"
        :class="{ active: $route.path == '/order/track' }"
        exact
        v-if="$permission('ORDER:TRACK')"
      >
        <div class="img" style="background-image: url('/images/track.png')" />
        <div class="title">
          <p>Theo dõi</p>
        </div>
      </router-link>

      <router-link
        tag="div"
        exact
        class="menu-item"
        to="/order/refund"
        v-if="$permission('ORDER:REFUND')"
      >
        <div class="img" style="background-image: url('/images/return.png')" />
        <div class="title">
          <p>Trả hàng</p>
        </div>
      </router-link> -->
      <router-link
        tag="div"
        class="menu-item"
        v-for="(menu, index) in menus"
        :key="index"
        :to="menu.link"
        v-if="$permission(menu.permission)"
        :class="{ active: $route.path == menu.link }"
      >
        <div
          :class="'img'"
          :style="{ backgroundImage: 'url(' + menu.icon + ')' }"
        />
        <div class="title">
          <p>{{ menu.title }}</p>
        </div>
      </router-link>
    </div>
    <div class="data-outer">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          title: "Tất cả đơn",
          icon: "/images/shopping-list.png",
          permission: "ORDER:ALL",
          link: "/order",
        },
        {
          title: "Bán POS",
          icon: "/images/tray.png",
          permission: "ORDER:NEW",
          link: "/order/pos",
        },
        {
          title: "Bán hàng",
          icon: "/images/b2b.png",
          permission: "ORDER:B2B",
          link: "/order/sale",
        },
        {
          title: "Lên nhiều đơn",
          icon: "/images/b2b.png",
          permission: "ORDER:B2B",
          link: "/order/bulk",
        },
        {
          title: "Đặt hàng",
          icon: "/images/shopping-cart.png",
          permission: "ORDER:CART",
          link: "/order/cart",
        },
        {
          title: "Theo dõi",
          icon: "/images/track.png",
          permission: "ORDER:TRACK",
          link: "/order/track",
        },
        {
          title: "Trả hàng",
          icon: "/images/return.png",
          permission: "ORDER:REFUND",
          link: "/order/refund",
        },
      ],
    };
  },
};
</script>
